import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="url(#paint0_linear_1485_5937)"
        fillRule="evenodd"
        d="M10.005 6.25a.75.75 0 10-.01 1.5l5.158.036-8.634 8.634a.75.75 0 001.061 1.06l8.633-8.632.037 5.157a.75.75 0 001.5-.01l-.05-6.951a.75.75 0 00-.745-.745l-6.95-.049z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1485_5937"
          x1="12.025"
          x2="11.811"
          y1="17.291"
          y2="6.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.002" stopColor="#7B61FF"></stop>
          <stop offset="1" stopColor="#FFAA27"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Icon;
