import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" viewBox="0 0 60 60">
      <path
        fill="#3B99FC"
        d="M12.288 18.305c9.782-9.74 25.642-9.74 35.425 0l1.177 1.172c.489.487.489 1.277 0 1.764l-4.027 4.01a.628.628 0 01-.886 0l-1.62-1.613c-6.825-6.795-17.889-6.795-24.713 0l-1.735 1.727a.628.628 0 01-.886 0l-4.027-4.01a1.243 1.243 0 010-1.763l1.292-1.287zm43.754 8.293l3.584 3.569c.489.487.489 1.276 0 1.763L43.464 48.023a1.256 1.256 0 01-1.771 0L30.222 36.6a.314.314 0 00-.443 0l-11.47 11.422a1.256 1.256 0 01-1.772 0L.375 31.93a1.243 1.243 0 010-1.763l3.584-3.57a1.256 1.256 0 011.771 0L17.201 38.02c.122.122.32.122.443 0l11.47-11.421a1.256 1.256 0 011.772 0l11.47 11.421c.123.122.321.122.444 0l11.47-11.421a1.256 1.256 0 011.771 0z"
      ></path>
    </svg>
  );
}

export default Icon;
