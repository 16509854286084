import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <g clipPath="url(#clip0_2433_5674)">
        <path
          fill="#fff"
          fillOpacity="0.5"
          d="M.002 18.453C.002 8.676 8.06.75 18.002.75 27.941.75 36 8.676 36 18.453c0 7.76-5.077 14.352-12.142 16.743-.431-.306-.927-.683-.927-1.318 0-.974.021-3.478.021-3.478s.781.13 1.897.13c2.81 0 3.697-1.753 4.12-2.734.55-1.279 1.264-1.879 2.022-2.359.465-.295.572-.642.034-.742-2.487-.458-3.123 2.76-4.784 3.272-1.185.365-2.71.207-3.468-.273-.1-.982-.824-1.832-1.427-2.28 4.225-.396 6.729-1.831 8.028-4.138l.14-.258.326-.733.096-.262c.407-1.171.586-2.512.586-4.01 0-2.658-.847-3.662-1.984-5.08.87-3.03-.313-5.103-.313-5.103s-1.829-.37-5.285 2.05c-1.873-.785-6.868-.85-9.23-.174-1.45-.937-4.104-2.268-5.174-1.895-.29.456-.914 1.787-.38 4.709-.362.641-2.252 2.006-2.259 5.867.031 1.424.182 2.625.463 3.642l.156.52s.089.246.187.48l.114.258c1.253 2.692 3.823 3.697 7.977 4.116-1.346.828-1.732 1.868-1.732 4.682 0 2.813.039 3.191.03 3.838-.009.573-.474.984-.892 1.295C5.105 32.844 0 26.236 0 18.455l.002-.002z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2433_5674">
          <path fill="#fff" d="M0 0H36V36H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
