import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        fillOpacity="0.5"
        d="M20.314 18c0 5.38-4.537 9.75-10.157 9.75S0 23.38 0 18s4.537-9.75 10.157-9.75S20.314 12.62 20.314 18zM31.44 18c0 5.058-2.279 9.169-5.078 9.169-2.798 0-5.078-4.111-5.078-9.169 0-5.058 2.28-9.169 5.079-9.169 2.798 0 5.078 4.09 5.078 9.17zM36 18c0 4.541-.79 8.222-1.783 8.222-.993 0-1.783-3.68-1.783-8.222 0-4.541.79-8.222 1.783-8.222C35.21 9.778 36 13.458 36 18z"
      ></path>
    </svg>
  );
}

export default Icon;
