let eventsData = [
  // {
  //   id: "quickperp-trade-verified",
  //   title: "Quickperp Official Domains",
  //   isActive: true,
  //   validTill: "02/01/2023 12:00:00 PM",
  //   bodyText: "Please verify that you are on the correct domain. https://perps.quickswap.exchange/",
  //   buttons: [
  //     {
  //       text: "app.quickperp.org",
  //       link: "https://app.quickperp.org",
  //     },
  //     {
  //       text: "perps.quickswap.exchange",
  //       link: "https://perps.quickswap.exchange",
  //     }
  //   ],
  // },
];
export default eventsData;
