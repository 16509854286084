import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <g clipPath="url(#clip0_2433_5696)">
        <path
          fill="#fff"
          fillOpacity="0.5"
          d="M30.474 6.888a30.399 30.399 0 00-7.327-2.183c-.046-.01-.091.014-.117.053a19.48 19.48 0 00-.912 1.804c-2.769-.4-5.522-.4-8.23 0a17.432 17.432 0 00-.928-1.804.123.123 0 00-.116-.053 30.4 30.4 0 00-7.327 2.183.197.197 0 00-.05.04C.801 13.63-.477 20.165.146 26.623c0 .03.02.064.046.083a30.279 30.279 0 008.99 4.367c.046.015.096 0 .127-.039a20.575 20.575 0 001.84-2.875c.03-.059 0-.127-.06-.151a20.184 20.184 0 01-2.81-1.287.11.11 0 01-.01-.186c.188-.136.375-.277.558-.419a.123.123 0 01.117-.014c5.891 2.588 12.27 2.588 18.09 0a.102.102 0 01.117.014c.183.142.37.288.558.42a.11.11 0 01-.01.185 19.12 19.12 0 01-2.809 1.287c-.066.024-.091.092-.06.155a23.158 23.158 0 001.835 2.876c.03.04.08.054.127.04a30.235 30.235 0 009.005-4.368c.025-.02.045-.049.045-.078.75-7.467-1.257-13.95-5.324-19.697a.071.071 0 00-.045-.039v-.01zM12.033 22.696c-1.775 0-3.235-1.565-3.235-3.49 0-1.926 1.434-3.49 3.235-3.49 1.8 0 3.265 1.579 3.234 3.49 0 1.92-1.434 3.49-3.234 3.49zm11.96 0c-1.774 0-3.234-1.565-3.234-3.49 0-1.926 1.435-3.49 3.235-3.49 1.8 0 3.265 1.579 3.235 3.49 0 1.92-1.42 3.49-3.235 3.49z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2433_5696">
          <path fill="#fff" d="M0 0H36V36H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
